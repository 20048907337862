<template>
  <b-container fluid>
    <b-row class="heading_alignment">
      <b-col md="12">
        <iq-card style="padding-left: 20px; padding-right: 20px; width: 100%">
          <template v-slot:headerTitle>
            <h4 class="card-title" style="display: flex;justify-content: space-between;">
              {{ cvCardTitle }}
              <b-button variant="primary" class="pull-right pointer mb-4" @click="goToOrgSaasDashboard">Dashboard</b-button>
            </h4>
            <span>On your <span class="primary-color">(DNS)</span> Domain Name Service Provider <span class="primary-color">(Eg. GoDaddy, WIX, BigRock, from where you purchaed your domain)</span>, you will have to add a <span class="primary-color">CNAME</span> record. This record will help us navigate the users to your landing page on GIDE.AI</span><br /><br />

            <h5>Typical configuration steps to be executed on your domain name service (DNS) provider:</h5>
            <ul style="list-style: disc;">
              <li>In the <span class="primary-color">CNAME (Aliases)</span> section, click the <span class="primary-color">+Add Record</span></li>
              <li>For <span class="primary-color">Host Name</span> copy/paste the Host Name info from the CNAME below</li>
              <li>For <span class="primary-color">Value</span> copy/paste the Points To info from the CNAME below</li>
              <li>For <span class="primary-color">TTL</span> choose <span class="primary-color">30 minuites</span></li>
              <li>Click <span class="primary-color">Save</span></li>
              <li>Click <span class="primary-color">Save Changes</span> in the pop-up</li>
            </ul>
            <br />
            <div class="domain_details_container mb-4">
              <div style="border-bottom: 1px solid #000;" class="org_domain mb-2">
                <span style="width:inherit">CNAME</span>
              </div>
              <!-- Host Name -->
              <div>
                <h5>
                  Host Name
                </h5>
                <b-form>
                  <div class="col-md-12 mb-3 mt-3 p-0">
                    <input v-model="vmOrgDomainValidateFormData.org_domain" type="text" class="domain_fields">
                    <span class="ml-2 primary-color pointer" style="font-weight: bold;" title="copy to clipboard" @click="doCopy(vmOrgDomainValidateFormData.org_domain)">Copy</span>
                  </div>
                </b-form>
              </div><!-- Host Name -->

              <!-- Points To -->
              <div>
                <h5>
                  Points To
                </h5>
                <b-form>
                  <div class="col-md-12 mb-3 mt-3 p-0">
                    <input v-model="cnameDomain" type="text" class="domain_fields">
                    <span class="ml-2 primary-color pointer" style="font-weight: bold;" title="copy to clipboard" @click="doCopy(cnameDomain)">Copy</span>
                  </div>
                </b-form>
              </div><!-- Points To -->
            </div>
          </template>
          <template>
            <b-row>
              <b-toast no-auto-hide v-model="showStaticToast" name="Toast" :variant="toastVariant" :title="toastTitle">
                <div v-html="toastMsg">
                </div>
              </b-toast>
              <b-col class="container_minheight" md="12">
                <b-button v-if="hasWhiteLabelingEnabledInSubsPlan" variant="primary" class="pull-right pointer mb-4" @click="startDomainValidation">Start Validation</b-button>
                <b-table
                  hover
                  responsive
                  :items="items"
                  :fields="columns"
                >
                  <template v-slot:cell(steps)="data">
                    <div style="display: flex; justify-content: space-between; align-items: center">
                      <p>
                        <span v-html="data.item.steps"></span><br/>
                      </p>
                    </div>
                  </template>
                  <template v-slot:cell(completion_code)="data">
                    <div>
                      <i v-if="processRankMap[domainValidationStatus] >= processRankMap[data.item.completion_code]" class="fa-solid fa-check fa-lg" style="color: green;" ria-hidden="true"></i>
                      <div v-if="data.item.status == 'IN_PROGRESS'">
                        <b-spinner variant="primary" label="Spinning"></b-spinner>
                      </div>
                      <div v-if="data.item.status == 'PROCESS_TIMEOUT'">
                        <b-button variant="primary" class="mt-2" v-if="data.item.completion_code == 'SSL_CERTIFICATE_GENERATED' && data.item.status == 'PROCESS_TIMEOUT' && processRankMap[domainValidationStatus] < processRankMap[data.item.completion_code]" @click="checkOrgDomainConfigurationStatus()">Check Status</b-button>
                        <b-button v-else-if="hasWhiteLabelingEnabledInSubsPlan" variant="primary" class="pointer" @click="startDomainValidation">Retry</b-button>
                      </div>
                      <div v-if="data.item.status == 'FAILED'">
                        <i class="fa-solid fa-xmark fa-lg" style="color: red;" ria-hidden="true"></i><!-- Failure -->
                        <b-button v-if="hasWhiteLabelingEnabledInSubsPlan" variant="primary" class="ml-2 pointer" @click="startDomainValidation">Retry</b-button>
                      </div>
                    </div>
                  </template>
                  <template v-slot:cell(toastMsg)="data">
                    <div style="display: flex; justify-content: space-between; align-items: center">
                      <div v-if="orgData && (orgData.org_domain_is_validated || orgData.org_domain_is_validated == 0) && setFailureIconVisibility(data.item)">
                        {{ toastMsg }}
                      </div>
                    </div>
                  </template>
                </b-table>
                <span class="pull-left mb-4 ml-2">
                  <span class="warning-color">It take couple of minutes for the configuration on your DNS Provider to propogate on internet. During the validation process if you see an error message then just wait for a couple of minutes to</span>&nbsp;
                </span>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :variant="toastVariant"
      :title="toastTitle"
    >
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { socialvue } from "../../../config/pluginInit"
import { Organisations } from "../../../FackApi/api/organisation"
import { OrgSaas } from "../../../FackApi/api/orgSaas"
import ApiResponse from "../../../Utils/apiResponse"

export default {
  name: "OrgSaasDomainValidate",
  async mounted () {
    socialvue.index()
    this.orgType = this.$route.params.org_type
    this.cvOrgId = this.$route.params.org_id

    await this.checkIsWhiteLabelingAvailableInSubsPlan()
    await this.getOrgDetails()
  },
  data () {
    return {
      cvCardTitle: "Validate Your Domain",
      cvOrgDomain: "organisation domain",
      vmOrgDomainValidateFormData: Object.assign({}, this.initFormData()),
      cvSubmitBtn: "Validate",
      showToast: false,
      showStaticToast: false,
      toastVariant: "default",
      toastTitle: "Website Management",
      toastMsg: "",
      orgType: null,
      cvOrgId: null,
      orgData: null,
      domainValidationStatus: "NOT_VALIDATED",
      propOpenedInModal: false,
      cnameDomain: process.env.VUE_APP_CNAME_DOMAIN,
      items: [
        { steps: "Step 1: Validating Your Domain", completion_code: "HTTP_VALIDATION_COMPLETE", status: "" },
        { steps: "Step 2: Generating Security (SSL) Certificates for Your Domain", completion_code: "SSL_CERTIFICATE_GENERATED", status: "" },
        { steps: "Step 3: Verifying Your Domain's Security Certificate (HTTPs/SSL)", completion_code: "HTTP_S_SSL_PROCESS_COMPLETE", status: "" }
      ],
      columns: [
        { label: "3 Step Validation", key: "steps", class: "text-left align-middle w-300px" },
        { label: "Status", key: "completion_code", class: "text-left align-middle" }
      ],
      processRankMap: {
        "NOT_VALIDATED": 1,
        "HTTP_VALIDATION_COMPLETE": 2,
        "SSL_CERTIFICATE_GENERATED": 3,
        "HTTP_S_SSL_PROCESS_COMPLETE": 4
      },
      hasWhiteLabelingEnabledInSubsPlan: true
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  methods: {
    /**
     * checkIsWhiteLabelingAvailableInSubsPlan
     */
    async checkIsWhiteLabelingAvailableInSubsPlan () {
      try {
        const isWhileLabelResp = await OrgSaas.orgSaasSubsPlanCheck(this, { org_id: this.cvOrgId })
        if (!isWhileLabelResp.resp_status || isWhileLabelResp.resp_data.data.is_whitelabling_allowed == false) {
          this.hasWhiteLabelingEnabledInSubsPlan = false

          // Whitelabeling not available in subscritption plan
          this.toastMsg = "Your plan does not include whitelabeling. Redirecting you back to the organisation dashboard"
          this.toastVariant = "danger"
          this.showToast = true
          setTimeout(() => {
            this.$router.push(`/org_edit/${this.orgData.type}/${this.orgData.org_id}`)
          }, 3000)
        }
      }
      catch (err) {
        console.error("Exception in checkIsWhiteLabelingAvailableInSubsPlan() and err: ", err.message)
      }
    },
    /**
     * getOrgDetails
     */
    async getOrgDetails () {
      try {
        const orgViewResp = await Organisations.organisationView(this, this.cvOrgId)
        if (!orgViewResp.resp_status) {
          ApiResponse.responseMessageDisplay(this, orgViewResp)
          return
        }

        this.orgData = orgViewResp.resp_data.data
        this.domainValidationStatus = this.orgData.org_domain_is_validated
        if (this.orgData.org_domain) {
          this.vmOrgDomainValidateFormData.org_domain = this.orgData.org_domain
        }

        if (this.orgData.org_domain_is_validated == "HTTP_S_SSL_PROCESS_COMPLETE") {
          // DOMAIN_VALIDATION_COMPLETE
          this.toastMsg = "Your domain has already been verified & validated. Redirecting you back to the organisation dashboard"
          this.toastVariant = "danger"
          this.showToast = true
          setTimeout(() => {
            this.$router.push(`/org_edit/${this.orgData.type}/${this.orgData.org_id}`)
          }, 3000)
        }
      }
      catch (err) {
        console.error("Exception in getOrgDetails and err: ", err)
      }
    },
    doCopy: function (msg) {
      var dummy = document.createElement("textarea")
      document.body.appendChild(dummy)
      dummy.value = msg
      dummy.select()
      document.execCommand("copy")
      document.body.removeChild(dummy)
    },
    /**
     * initFormData
     */
    initFormData () {
      return {
        org_domain: ""
      }
    },
    /**
     * setLocalProcessStatusVariable
     */
    setLocalProcessStatusVariable (completionCode, status) {
      const pIndex = this.items.findIndex(e => e.completion_code == completionCode)
      if (pIndex != -1) {
        this.items[pIndex].status = status
      }
    },
    /**
     * timeoutProcess
     */
    timeoutProcess (completionCode, timeout = 15000) {
      // default timeout 30 secs / 30000 miliseconds
      setTimeout(() => {
        const pIndex = this.items.findIndex(e => e.completion_code == completionCode)
        if (pIndex != -1 && this.items[pIndex].status != "DONE" && this.items[pIndex].status != "FAILED") {
          this.items[pIndex].status = "PROCESS_TIMEOUT"
        }
      }, timeout)
    },
    /**
     * startDomainValidation
     */
    async startDomainValidation () {
      try {
        if (!this.vmOrgDomainValidateFormData.org_domain) {
          this.toastMsg = "Fill in the mandatory fields"
          this.toastVariant = "danger"
          this.showToast = true
          return
        }

        const domainNameRegex = /^[a-zA-Z0-9-]{1,63}(\.[a-zA-Z0-9-]{1,63})*\.(com|org|net|edu|gov|io|[a-zA-Z]{2,})$/
        if (!domainNameRegex.test(this.vmOrgDomainValidateFormData.org_domain)) {
          this.toastMsg = "Invalid Domain Name"
          this.toastVariant = "danger"
          this.showToast = true
          return
        }

        if (this.orgData.org_domain_is_validated == "HTTP_S_SSL_PROCESS_COMPLETE") {
          // domain validation complete
          this.toastMsg = "Your domain has already been verified & validated. Redirecting you back to the organisation dashboard"
          this.toastVariant = "danger"
          this.showToast = true
          setTimeout(() => {
            this.$router.push(`/org_edit/${this.orgData.type}/${this.orgData.org_id}`)
          }, 3000)
          return
        }

        if (this.domainValidationStatus == "NOT_VALIDATED" || this.domainValidationStatus == null) {
          this.setLocalProcessStatusVariable("HTTP_VALIDATION_COMPLETE", "IN_PROGRESS")
          this.timeoutProcess("HTTP_VALIDATION_COMPLETE")
          // domain validation on http protocol started
          const validateDomainOnProtocolResp = await this.validateDomainOnPort(this.vmOrgDomainValidateFormData.org_domain, 80)
          if (!validateDomainOnProtocolResp.resp_status) {
            this.setLocalProcessStatusVariable("HTTP_VALIDATION_COMPLETE", "FAILED")
            this.displayErrMsg("Your domain is not correctly pointed to the GIDE.AI CNAME. Refer to the DNS Settings above")
            return
          }

          // domain validation on http protocol completed
          this.domainValidationStatus = "HTTP_VALIDATION_COMPLETE"
          this.setLocalProcessStatusVariable("HTTP_VALIDATION_COMPLETE", "DONE")
        }

        if (this.domainValidationStatus == "HTTP_VALIDATION_COMPLETE") {
          this.setLocalProcessStatusVariable("SSL_CERTIFICATE_GENERATED", "IN_PROGRESS")
          this.timeoutProcess("SSL_CERTIFICATE_GENERATED")
          // ssl certificate generation process started
          const sslCertGenResp = await this.generateSSLCertificate()
          if (!sslCertGenResp.resp_status) {
            this.setLocalProcessStatusVariable("SSL_CERTIFICATE_GENERATED", "FAILED")
            this.displayErrMsg("Security certificate generation or validation for your domain failed. After CNAME mapping it may take some time for the domain settings to reflect.Pls re-try again after 5 mins. If the error persists, please contact support@gide.ai")
            return
          }
          /*
            Wait for 2 secs before validating the domain on HTTPs protocol because the certificate talkes a slight amount of time before
            it starts getting served with the request and if we make an early request to the domain the axios package at the backend may
            throw an exception
          */
          await new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve()
            }, 2000)
          })

          // ssl certificate generation process completed
          this.domainValidationStatus = "SSL_CERTIFICATE_GENERATED"
          this.setLocalProcessStatusVariable("SSL_CERTIFICATE_GENERATED", "DONE")
        }

        if (this.domainValidationStatus == "SSL_CERTIFICATE_GENERATED") {
          // domain validation on https protocol started
          this.setLocalProcessStatusVariable("HTTP_S_SSL_PROCESS_COMPLETE", "IN_PROGRESS")
          this.timeoutProcess("HTTP_S_SSL_PROCESS_COMPLETE")
          let retries = 3
          let validateDomainOnHttpsResp = null
          while (retries > 0) {
            validateDomainOnHttpsResp = await this.validateDomainOnPort(this.vmOrgDomainValidateFormData.org_domain, 443)
            if (validateDomainOnHttpsResp.resp_status) {
              break
            }

            // wait for 2 secs between each retry
            await new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve()
              }, 2000)
            })

            retries--
          }

          if (retries == 0 && !validateDomainOnHttpsResp.resp_status) {
            this.setLocalProcessStatusVariable("HTTP_S_SSL_PROCESS_COMPLETE", "FAILED")
            this.displayErrMsg("Validation of domain on HTTPs protocol failed")
            return
          }
          else if (validateDomainOnHttpsResp.resp_status) {
            // domain validation on http protocol completed
            this.domainValidationStatus = "HTTP_S_SSL_PROCESS_COMPLETE"
            this.setLocalProcessStatusVariable("HTTP_S_SSL_PROCESS_COMPLETE", "DONE")
            this.items.push({ steps: `<b style="color: #109618; font-weight: bold;">Domain verification succssful. Domain is now secured and active. Please visit your website: </b><a href="https://${this.vmOrgDomainValidateFormData.org_domain}" target="_blank">${this.vmOrgDomainValidateFormData.org_domain}</a>`, state: "COMPLETED" })
          }
        }
      }
      catch (err) {
        console.error("Exception in startDomainValidation() and err: ", err)
      }
    },

    /**
     * generateSSLCertificate
     */
    async generateSSLCertificate () {
      try {
        // ssl certificate generation process started
        const certgenObj = {
          domain: this.vmOrgDomainValidateFormData.org_domain,
          ca_authorised: true,
          org_id: this.orgData.org_id,
          user_id: this.userData.user_id
        }
        const sslCertGenResp = await Organisations.organisation_domain_generate_sslcert(this, certgenObj)
        return sslCertGenResp
      }
      catch (err) {
        console.error("Exception in generateSSLCertificate and err: ", err)
      }
    },
    /**
     * validateDomainOnPort
     */
    async validateDomainOnPort (domain, port) {
      try {
        const domainValidateObj = {
          org_id: this.cvOrgId,
          domain: domain,
          port: port
        }
        const validateDomainOnProtocolResp = await Organisations.organisation_domain_validate_on_port(this, domainValidateObj)
        return validateDomainOnProtocolResp
      }
      catch (err) {
        console.error("Exception in validateDomainOnPort and err: ", err)
      }
    },
    /**
     * displayErrMsg
     */
    displayErrMsg (msg) {
      this.toastMsg = msg
      this.toastVariant = "danger"
      this.showStaticToast = true
    },
    /**
     * checkOrgDomainConfigurationStatus
     */
    async checkOrgDomainConfigurationStatus () {
      await this.getOrgDetails()
      if (this.domainValidationStatus == "SSL_CERTIFICATE_GENERATED") {
        this.setLocalProcessStatusVariable("SSL_CERTIFICATE_GENERATED", "DONE")
      }
    },
    /**
     * goToOrgSaasDashboard
     */
    goToOrgSaasDashboard () {
      this.$router.push("/dashboard")
    }
  }
}
</script>

<style>
  .domain_details_container {
    padding: 1.2rem;
    width: max-content;
    background-color: whitesmoke;
  }
  .domain_fields {
    border: 1px solid #000;
    padding: 0.1rem;
  }
  li::marker {
    color: #e5252c !important;
  }
</style>
